import React, { useState } from "react";
import { useQuery } from "react-query";
import { styled } from "styled-components";
import { getEvent } from "../api";
// import DownloadLink from "./Download";
import ExcelButton2 from "./ExcelButton2";
import EventDelete from "./eventDelete";

const Sul = styled.ul`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  li {
    padding: 0 0 0.5rem 0;
    input {
      padding: 0.5rem;
      border: 0;
      border-radius: 2px;
    }
  }
`;

const Tul = styled.ul`
  background-color: #6ab04c;
  display: flex;
  font-size: 0.9rem;
  li {
    padding: 0.7rem;
    border-bottom: 1px solid #eee;
    text-align: center;
    color: white;
  }
  li:first-child {
    flex-basis: 10%;
  }
  li:nth-child(2),
  li:nth-child(3) {
    flex-basis: 10%;
  }

  li:nth-child(4) {
    flex-basis: 20%;
  }
  li:nth-child(5) {
    text-align: left;
    flex-basis: 35%;
  }
  li:nth-child(6) {
    flex-basis: 15%;
  }
  li:nth-child(7) {
    flex-basis: 5%;
  }
`;
const Ul = styled.ul`
  background-color: #fff;
`;
const Li = styled.li`
  display: flex;
  font-size: 0.9rem;
  div {
    padding: 0.7rem;
    border-bottom: 1px solid #eee;
    text-align: center;
  }
  div:first-child {
    flex-basis: 10%;
  }
  div:nth-child(2),
  div:nth-child(3) {
    flex-basis: 10%;
  }
  ,
  div:nth-child(4) {
    flex-basis: 20%;
  }

  div:nth-child(5) {
    text-align: left;
    flex-basis: 35%;
  }
  div:nth-child(6) {
    flex-basis: 15%;
  }
  li:nth-child(7) {
    flex-basis: 5%;
  }
`;

interface IData {
  순번: number;
  이름: string;
  생년월일: string;
  휴대전화번호: string;
  인스타그램: string;
  접수일시: string;
}

const EventList: React.FC = () => {
  const [key, SetKey] = useState<string>("");
  const { data, isLoading } = useQuery<IData[]>(
    "allEventData",
    getEvent
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetKey(event.target.value);
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  let i = 0;

  return (
    <div>
      <Sul>
        <li>
          <input
            type="text"
            name="s_key"
            value={key}
            onChange={handleChange}
            />
        </li>
        <li>
          <ExcelButton2 />
        </li>
      </Sul>
      <Tul>
        <li>순번</li>
        <li>신청자</li>
        <li>생년월일</li>
        <li>휴대전화번호</li>
        <li>인스타그램</li>
        <li>접수일</li>
        <li></li>
      </Tul>
      <Ul>
        {data?.map((item) => {
          i++;

          return (
            <Li key={item.순번}>
              <div>{i}</div>
              <div>{item.이름}</div>
              <div>{item.생년월일}</div>
              <div>{item.휴대전화번호}</div>
              <div><a href={item.인스타그램} target="_blank" rel="noopener noreferrer">{item.인스타그램}</a></div>
              <div>{item.접수일시?.substring(0, 10)}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EventDelete idx={item.순번} />
              </div>
            </Li>
          );
        })}
      </Ul>
    </div>
  );
};

export default EventList;
