// import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { styled } from "styled-components";
// import { useQuery } from "react-query";
import { getCnt, postData } from "../api";
import { Cont, ContWrapper, Title } from "../assets/styles/StyleComponent";
import ZipCodeBtn from "../components/ZipCodeBtn";

const Left = styled.div`
  font-family: "Pretendard-Bold";
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 200%;
  color: #e77575;
`;
const Right = styled.div`
  line-height: 200%;
  padding-left: 0px;
  color: #333;
`;
const Input = styled.input`
  padding: 8px;
  border: 1px solid silver;
  border-radius: 4px;
  font-size: 1rem;
  max-width: 100%;
`;

const Btn = styled.button`
  border: 1px solid green;
  background-color: green;
  padding: 0.5rem 1rem;
  margin: 24px 0;
  border-radius: 4px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
`;

const Box = styled.div`
  background-color: #fff;
  color: #333;
  border-radius: 16px;
  height: 400px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 4px 20px;
  }
`;

const Abox = styled.div`
  margin: 4rem 0 2rem 0;
  text-align: center;
  color: crimson;
`;

type FormData = {
  name: string;
  jumin: string;
  gender: string;
  company: string;
  bizno1: string;
  bizno2: string;
  bizno3: string;
  zipcode: string;
  addr: string;
  addr_extra: string;
  tel: string;
  mobile: string;
  mobile_extra: string;
  email: string;
  relation: string;
  child_name: string;
  child_jumin: string;
  child_gender: string;
  school: string;
  grade: string;
  child_mobile: string;
  file: File | null;
  file2: File | null;
};

function Receipt() {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    jumin: "",
    gender: "",
    company: "",
    bizno1: "",
    bizno2: "",
    bizno3: "",
    zipcode: "",
    addr: "",
    addr_extra: "",
    tel: "",
    mobile: "",
    mobile_extra: "",
    email: "",
    relation: "",
    child_name: "",
    child_jumin: "",
    child_gender: "",
    school: "",
    grade: "",
    child_mobile: "",
    file: null,
    file2: null,
  });
  const [upfile, setUpFile] = useState<any | null>(null);
  const [upfile2, setUpFile2] = useState<any | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isMajor, setMajor] = useState<string>("참가자");
  const [cntOk, setCntOk] = useState<boolean>(true);
  const [timeOk, setTimeOk] = useState<boolean>(true);
  const openTime = new Date("2024-08-23T09:00:00");
  const [divs, setDivs] = useState<boolean>(true);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setTimeOk(openTime < now);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    getCnt().then((data) => {
      const n = data.res.n === undefined ? 0 : data.res.n;
      console.log(data.res.y, Number(n));
      if (Number(data.res.y) >= 120) setDivs(false);
      if (Number(data.res.y) + Number(n) >= 150) setCntOk(false);
    });
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (e.target.id === "file") {
        setFormData({
          ...formData,
          file: e.target.files[0],
        });
        setUpFile(e.target.files[0]);
      } else if (e.target.id === "file2") {
        setFormData({
          ...formData,
          file2: e.target.files[0],
        });
        setUpFile2(e.target.files[0]);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newData = new FormData();
    newData.append("file", upfile);
    newData.append("file2", upfile2);
    newData.append("name", formData.name);
    newData.append("jumin", formData.jumin);
    newData.append("gender", formData.gender);
    newData.append("mobile", formData.mobile);
    newData.append("company", formData.company);
    newData.append("bizno1", formData.bizno1);
    newData.append("bizno2", formData.bizno2);
    newData.append("bizno3", formData.bizno3);
    newData.append("zipcode", formData.zipcode);
    newData.append("addr", formData.addr);
    newData.append("addr_extra", formData.addr_extra);
    newData.append("tel", formData.tel);
    newData.append("mobile_extra", formData.mobile_extra);
    newData.append("email", formData.email);
    newData.append("relation", formData.relation);
    newData.append("child_name", formData.child_name);
    newData.append("child_jumin", formData.child_jumin);
    newData.append("child_gender", formData.child_gender);
    newData.append("school", formData.school);
    newData.append("grade", formData.grade);

    try {
      postData(
        formData.name,
        formData.jumin,
        formData.gender,
        formData.company,
        formData.bizno1,
        formData.bizno2,
        formData.bizno3,
        formData.zipcode,
        formData.addr,
        formData.addr_extra,
        formData.tel,
        formData.mobile,
        formData.mobile_extra,
        formData.email,
        formData.relation,
        formData.child_name,
        formData.child_jumin,
        formData.child_gender,
        formData.school,
        formData.grade,
        upfile,
        upfile2
      ).then((data) => {
        if (data.data.reason === "exist") {
          alert("이미 신청되어 있습니다.");
        } else {
          // setStatus("Data submitted successfully");
          console.log(data.data);
          data.data.is_major === "y"
            ? setMajor("참가자")
            : setMajor("예비명단으");
          setSubmitted(true);
        }
      });
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  const autoHyphen = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.currentTarget.value = e.currentTarget.value
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(-{1,2})$/g, "");
  };

  return (
    <HelmetProvider>
      <>
        <Helmet>
          <title>참가접수</title>
        </Helmet>
        <Title>참가접수</Title>
        {!timeOk ? (
          <Box>2024년 8월 23일 9시 부터 접수가 시작됩니다.</Box>
        ) : !cntOk ? (
          <Box>접수가 마감되었습니다.</Box>
        ) : submitted ? (
          <Box>
            {formData.name}님 {isMajor}로 접수되었습니다.
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <ContWrapper>
              {/* {!divs && <Abox>예비명단으로 접수됩니다.</Abox>} */}
              <Cont>
                <div
                  style={{
                    fontFamily: "Pretendard-ExtraBold",
                    fontSize: "1.1rem",
                    color: "royalblue",
                  }}
                >
                  근로자 인적사항
                </div>
                <hr />
              </Cont>
              <Cont>
                <Left>참가자이름</Left>
                <Right>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>주민등록번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="jumin"
                    id="jumin"
                    size={7}
                    maxLength={6}
                    required
                    value={formData.jumin}
                    onChange={handleChange}
                    pattern="[0-9]{6}"
                  />{" "}
                  -{" "}
                  <Input
                    type="text"
                    name="gender"
                    id="gender"
                    size={2}
                    maxLength={1}
                    required
                    value={formData.gender}
                    onChange={handleChange}
                  />{" "}
                  * * * * * *
                </Right>
              </Cont>
              <Cont>
                <Left>회사명</Left>
                <Right>
                  <Input
                    type="text"
                    name="company"
                    id="company"
                    required
                    value={formData.company}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>사업자등록번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="bizno1"
                    id="bizno1"
                    size={3}
                    maxLength={3}
                    required
                    value={formData.bizno1}
                    onChange={handleChange}
                    pattern="[0-9]{3}"
                  />{" "}
                  -{" "}
                  <Input
                    type="text"
                    name="bizno2"
                    id="bizno2"
                    size={2}
                    maxLength={2}
                    required
                    value={formData.bizno2}
                    onChange={handleChange}
                    pattern="[0-9]{2}"
                  />{" "}
                  -{" "}
                  <Input
                    type="text"
                    name="bizno3"
                    id="bizno3"
                    size={5}
                    maxLength={5}
                    required
                    value={formData.bizno3}
                    onChange={handleChange}
                    pattern="[0-9]{5}"
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>우편번호</Left>
                <Right className="flex">
                  <Input
                    type="text"
                    name="zipcode"
                    id="zipcode"
                    size={6}
                    maxLength={5}
                    required
                    readOnly
                    value={formData.zipcode}
                    onChange={handleChange}
                    pattern="[0-9]{5}"
                  />{" "}
                  <ZipCodeBtn setFormData={setFormData} />
                </Right>
              </Cont>
              <Cont>
                <Left>자택주소(우편물수령지)</Left>
                <Right>
                  <Input
                    type="text"
                    name="addr"
                    id="addr"
                    style={{ width: "100%" }}
                    required
                    value={formData.addr}
                    onChange={handleChange}
                  />{" "}
                  <Input
                    type="text"
                    name="addr_extra"
                    id="addr_extra"
                    style={{ width: "100%" }}
                    required
                    placeholder="나머지 주소"
                    value={formData.addr_extra}
                    onChange={handleChange}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>전화번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="tel"
                    id="tel"
                    maxLength={13}
                    style={{ width: "100%" }}
                    value={formData.tel}
                    onChange={handleChange}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>휴대전화번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    required
                    maxLength={13}
                    value={formData.mobile}
                    onChange={handleChange}
                    onInput={autoHyphen}
                    pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>
                  비상연락처 &nbsp;<small>(본인 이외)</small>
                </Left>
                <Right>
                  <Input
                    type="text"
                    name="mobile_extra"
                    id="mobile_extra"
                    required
                    maxLength={13}
                    value={formData.mobile_extra}
                    onChange={handleChange}
                    onInput={autoHyphen}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>이메일주소</Left>
                <Right>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>자녀와의 관계</Left>
                <Right>
                  <Input
                    type="text"
                    name="relation"
                    id="relation"
                    required
                    value={formData.relation}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont style={{ marginTop: "48px" }}>
                <div
                  style={{
                    fontFamily: "Pretendard-ExtraBold",
                    fontSize: "1.1rem",
                    color: "royalblue",
                  }}
                >
                  자녀 인적사항
                </div>
                <hr />
              </Cont>
              <Cont>
                <Left>자녀이름</Left>
                <Right>
                  <Input
                    type="text"
                    name="child_name"
                    id="child_name"
                    required
                    value={formData.child_name}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>자녀주민등록번호</Left>
                <Right>
                  <Input
                    type="text"
                    name="child_jumin"
                    id="child_jumin"
                    size={7}
                    maxLength={6}
                    required
                    value={formData.child_jumin}
                    onChange={handleChange}
                    pattern="[0-9]{6}"
                  />{" "}
                  -{" "}
                  <Input
                    type="text"
                    name="child_gender"
                    id="child_gender"
                    size={2}
                    maxLength={1}
                    required
                    value={formData.child_gender}
                    onChange={handleChange}
                  />{" "}
                  * * * * * *
                </Right>
              </Cont>
              <Cont>
                <Left>학교명 및 학년</Left>
                <Right className="flex">
                  <Input
                    type="text"
                    name="school"
                    id="school"
                    style={{ width: "90px" }}
                    required
                    value={formData.school}
                    onChange={handleChange}
                  />{" "}
                  학교{" "}
                  <Input
                    type="text"
                    name="grade"
                    id="grade"
                    size={2}
                    maxLength={1}
                    required
                    value={formData.grade}
                    onChange={handleChange}
                  />{" "}
                  학년
                </Right>
              </Cont>
              {/* <Cont>
                <Left>모자사이즈</Left>
                <Right className="flex">
                  <label>
                    <input
                      type="radio"
                      name="hat_size"
                      id="hat_size_a"
                      required
                      value="a"
                      onChange={handleChange}
                    />{" "}
                    성인용
                  </label>{" "}
                  <label>
                    <input
                      type="radio"
                      name="hat_size"
                      id="hat_size_c"
                      required
                      value="c"
                      checked
                      onChange={handleChange}
                    />{" "}
                    아동용
                  </label>
                </Right>
              </Cont> */}
              <Cont style={{ marginTop: "48px" }}>
                <div
                  style={{
                    fontFamily: "Pretendard-ExtraBold",
                    fontSize: "1.1rem",
                    color: "royalblue",
                  }}
                >
                  첨부파일
                </div>
                <hr />
              </Cont>
              <Cont>
                <Left>재직증명서(개인사업자는 사업자등록증)</Left>
                <Right>
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileChange}
                    required
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>
              <Cont>
                <Left>가족관계증명서(또는 주민등록등본)</Left>
                <Right>
                  <Input
                    type="file"
                    name="file2"
                    id="file2"
                    onChange={handleFileChange}
                    required
                    style={{ width: "100%" }}
                  />
                </Right>
              </Cont>

              <Cont style={{ marginTop: "48px" }}>
                <div
                  style={{
                    fontFamily: "Pretendard-ExtraBold",
                    fontSize: "1.1rem",
                    color: "royalblue",
                  }}
                >
                  개인정보수집방침동의
                </div>
                <hr />
              </Cont>
              <Cont>
                <ul style={{ lineHeight: "1.7rem" }}>
                  <li>[수집 및 이용목적] 행사 접수 및 진행</li>
                  <li>
                    [수집항목] 이름/휴대전화번호/주소/이메일주소/학교명 및 학년
                  </li>
                  <li>[보유 및 이용기간] 행사 종료일자 까지</li>
                  <li>
                    단, 관련 법령규정에 의하여 보존이 필요한 경우 해당기간
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        id="agree"
                        name="agree"
                        value="y"
                        required
                      />{" "}
                      동의합니다.
                    </label>
                  </li>
                </ul>
              </Cont>
              <Cont style={{ textAlign: "center", margin: "20px 0" }}>
                <Btn type="submit">접수하기</Btn>
              </Cont>
            </ContWrapper>
          </form>
        )}
      </>
    </HelmetProvider>
  );
}

export default Receipt;
