import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { styled } from "styled-components";
// import Logo from "../assets/images/icon-youtube.png";
import TopLogo from "../assets/images/logo.png";
import { userState } from "../atom";
import LogoutButton from "../components/LogOutButton";
import "../icons";

const NavWrapper = styled.div`
  background-color: #fff;
  height: 90px;
  width: 100%;
  position: fixed;
  top: 0;
  @media only screen and (max-width: 600px) {
    height: 90px;
  }
`;
const Nav = styled.div`
  width: 800px;
  margin: 0 auto;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media only screen and (max-width: 600px) {
    padding: 12px 4px;
    width: 100%;
    flex-direction: column;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 0.7rem;

  @media only screen and (max-width: 600px) {
    gap: 0.1rem;
    
  }
`;

const Btn = styled.button`
  font-family: "Pretendard-SemiBold";
  background-color: #fff;
  color: #333;
  height: 32px;
  border: 0;
  font-size: 1rem;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    transition: all 0.2s ease;
  }

  @media only screen and (max-width: 600px) {
    letter-spacing: -1px;
    font-size: 0.9rem;
    width: 100%;
    color: #333;
    background-color: transparent;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

// const Img = styled.img`
//   height: 40px;
//   &:hover {
//     scale: 1.1;
//     transition: all 0.2s ease;
//   }
// `;
const LogoImg = styled.img`
  height: 60px;
  &:hover {
    scale: 1.1;
    transition: all 0.2s ease;
  }
  @media only screen and (max-width: 600px) {
    height: 40px;
  }
`;

const Ylink = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;
const Mlink = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

const Mnav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: #333;
  background-opacity: 0.5;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 12px;
`;

function Menu() {
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  const user = useRecoilValue(userState);
  //setLogLevel(log_level);
  return (
    <NavWrapper>
      <Nav>
        <Link to="/">
          <LogoImg src={TopLogo} alt="main logo" />
        </Link>
        <LinkWrapper>
          <Link
            to={{
              pathname: `/overview`,
            }}
          >
            <Btn>행사개요</Btn>
          </Link>
          <Link
            to={{
              pathname: `/receipt_info`,
            }}
          >
            <Btn>접수방법</Btn>
          </Link>
          <Link
            to={{
              pathname: `/receipt`,
            }}
          >
            <Btn>참가접수</Btn>
          </Link>
          <Link
            to={{
              pathname: `/check`,
            }}
          >
            <Btn>접수확인</Btn>
          </Link>
          <Link
            to={{
              pathname: `/challenge`,
            }}
          >
            <Btn>열공응원챌린지</Btn>
          </Link>
          {user ? (
            <>
              <Link to={{ pathname: `/admin` }}>
                <Btn>접수관리</Btn>
              </Link>
              <Link to={{ pathname: `/event` }}>
                <Btn>응원접수관리</Btn>
              </Link>
            </>
          ) : null}
          {user ? (
            <LogoutButton />
          ) : (
            <></>
            // <Link to={{ pathname: `/login` }}>
            //   <Btn>로그인</Btn>
            // </Link>
          )}
        </LinkWrapper>
        <Ylink>
          {/* <Link to="https://youtube.com" target="_blank">
            <Img src={Logo} alt="youtube" />
          </Link> */}
        </Ylink>
        <Mlink>
          <Link to="" onClick={handleToggleOpen}>
            <FontAwesomeIcon icon="bars" />
          </Link>
        </Mlink>
      </Nav>
      {isToggleOpen ? (
        <Mnav>
          <div
            style={{
              width: "100%",
              color: "white",
              fontSize: "1.5rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/" onClick={handleToggleOpen}>
              <LogoImg src={TopLogo} alt="main logo" />
            </Link>
            <Link to="" onClick={handleToggleOpen}>
              {" "}
              <FontAwesomeIcon icon="xmark" />{" "}
            </Link>
          </div>
          <Link
            to={{
              pathname: `/overview`,
            }}
            onClick={handleToggleOpen}
          >
            <Btn>행사개요</Btn>
          </Link>
          <Link
            to={{
              pathname: `/receipt_info`,
            }}
            onClick={handleToggleOpen}
          >
            <Btn>접수방법</Btn>
          </Link>
          <Link
            to={{
              pathname: `/receipt`,
            }}
            onClick={handleToggleOpen}
          >
            <Btn>참가접수</Btn>
          </Link>
          <Link
            to={{
              pathname: `/check`,
            }}
            onClick={handleToggleOpen}
          >
            <Btn>접수확인</Btn>
          </Link>
          <Link
            to={{
              pathname: `/challenge`,
            }}
            onClick={handleToggleOpen}
          >
            <Btn>열공응원챌린지</Btn>
          </Link>
        </Mnav>
      ) : null}
    </NavWrapper>
  );
}

export default Menu;
